import styled from 'styled-components'
import * as palette from '../styles/variables';
import { device, width } from '../styles/device';
import {TitleWithTextStyled} from './titleWithTextStyled'


export const ProfileCompanyPageStyled = styled.div`
    padding-top: ${palette.headerTabletHeight};

    @media ${device.tablet} {
        padding-top: ${palette.headerHeight};
    }

    .content-wrapper {
        max-width: 100%;
        margin: 0px auto 0 auto;
        text-align: left;

        .section-wrapper.who-are-we  {
            width: 100%;
            background-color: ${palette.lightBg};

            .section-inner {
                margin: 0 auto;
                max-width: ${width.xxl};

                .section {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-top: 3rem;

                    @media ${device.tablet} {
                        flex-direction: row;  
                        padding-top: 0;   
                    }

                    .imagesWrapper {
                        width: 100%;
                        flex: 1 1;
                    }
                }
            }
        } 

        .section-wrapper.what-we-do  {
            width: 100%;
            margin-top: 2rem;

            @media ${device.tablet} {
                margin-top: 0;
            }

            .section-inner {
                margin: 0 auto;
                max-width: ${width.xxl};

                .section {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-top: 3rem;

                    @media ${device.tablet} {
                        flex-direction: row;
                        padding-top: 0;
                    }

                    .imagesWrapper {
                        width: 100%;
                        flex: 1 1;
                        order: 2;

                        @media ${device.tablet} {
                            order: 1;  
                        }
                    }
                }
            }
        }
        
        .section-wrapper.market  {
            width: 100%;
            background-color: ${palette.lightBg};

            .section-inner {
                margin: 0 auto;
                max-width: ${width.xxl};

                .section {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding-top: 3rem;
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-bottom: 6rem;

                    @media ${device.tablet} {
                        flex-direction: row;
                        padding-top: 6rem;
                    }

                    .imagesWrapper {
                        width: 100%;
                        flex: 1 1;

                        .innerWrap {
                            display: flex;
                            flex-wrap: wrap;
                        }

                        .gatsby-image-wrapper {
                            width: 50%;
                            max-height: unset;


                            img {
                                border: 1px solid white;
                            }
                        }
                    }
                }
            }
        } 
    }

`;

export const ProfileTitleInnerStyle = styled(TitleWithTextStyled)`

    flex: 1 1;
    
    &.who-are-we {
        margin-right: 20px;
    }

    &.what-we-do {
        margin-left: -20px;
        order: 1;

        @media ${device.tablet} {
            order: 2;  
        }

        .textBg {
            padding-left: 15px;

            @media ${device.tablet} {
                padding-left: 35px; 
            }
        }

        .container {
            padding-left: 20px;

            @media ${device.tablet} {
                padding-left: 40px; 
            }
        }
    }

    &.market {
        margin-right: 0;

        @media ${device.tablet} {
            margin-right: 20px; 
        }
    }

    .textBg {
        display: block;
        text-align: left;
        font-weight: 700;
        font-size: 88px;
        color: ${palette.textBg};
        margin-bottom: 10px;
    }
    .profile, .profile-market {
        text-align: left;

        .container {
            padding-bottom: 40px;
            

            .title {
                margin-bottom: 1rem;
                font-size: ${palette.fontSizeMdl};
                color: ${palette.black};
                text-transform: uppercase;
                font-weight: 700;
                text-align: left;

                p {
                    color: ${palette.black};
                }
            }
        }
    }

`;
