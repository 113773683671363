import React from "react"
import {ProfileCompanyPageStyled, ProfileTitleInnerStyle} from './profileCompanyPageStyled'
import TitleWithText from './titleWithText'
import ImageGroup from './imageGroup'



const ProfileCompanyPage = () => {
    return <ProfileCompanyPageStyled>
                <div className="content-wrapper">
                    <div className="section-wrapper who-are-we">
                        <div className="section-inner">
                            <div className="section">
                                <ProfileTitleInnerStyle className="who-are-we">¨
                                    <div className="textBg">SAVIE</div>
                                    <TitleWithText slug="who-are-we" className="profile" isLightText={false}/>
                                </ProfileTitleInnerStyle>
                                <ImageGroup slug="who-are-we"/>
                            </div>
                        </div>
                    </div>
                    <div className="section-wrapper what-we-do">
                        <div className="section-inner">
                            <div className="section">
                                <ProfileTitleInnerStyle className="what-we-do">
                                    <div className="textBg">SAVIE</div>
                                    <TitleWithText slug="what-we-do" className="profile" isLightText={false}/>
                                </ProfileTitleInnerStyle>
                                <ImageGroup slug="what-we-do"/>
                            </div>
                        </div>
                    </div>
                    <div className="section-wrapper market">
                        <div className="section-inner">
                            <div className="section">
                            <ProfileTitleInnerStyle  className="market">
                                <div className="textBg">SAVIE</div>
                                <TitleWithText slug="our-markets" className="profile-market" isLightText={false}/>
                            </ProfileTitleInnerStyle>
                            <ImageGroup slug="our-markets"/>
                            </div>
                        </div>
                    </div>
                </div>
            </ProfileCompanyPageStyled>
}
 export default ProfileCompanyPage

