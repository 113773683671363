import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { useStaticQuery, graphql } from "gatsby"
import ProfileCompanyPage from '../components/profileCompanyPage'




const Profile = () => {
    const data = useStaticQuery(graphql`
        query ProfilePageQuery {
            allContentfulMetaDescription(filter: {position: {eq: "Profile"}}) {
                edges {
                    node {
                        text
                    }
                }
            }
        }
`)
  if(!data.allContentfulMetaDescription.edges) {
    return <div>Loading...</div>
  }
  const descriptionMeta = data.allContentfulMetaDescription.edges[0].node.text ? data.allContentfulMetaDescription.edges[0].node.text : "Top Nitrile and Latex Gloves Manufacturer in Asia | Savie Glove by Savie Group"
    return (
        <Layout hideContact={false} backgroundColor={false} showMarketingPopup={true}>
        <SEO title="Savie Glove Profile company" description={descriptionMeta} />
        <ProfileCompanyPage />
    </Layout>
    )

}

export default Profile
